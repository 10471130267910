import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'store';
import { getInstallations } from 'store/slices/user';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

const MyDropdown = () => {
    const dispatch = useDispatch();
    const { installations } = useSelector((state) => state.user);
    const [selectedInstallationId, setSelectedInstallationId] = useState('');
    const [installationAddress, setinstallationAddress] = useState('');
    const [showFullNames, setShowFullNames] = useState(false);

    React.useEffect(() => {
        dispatch(getInstallations());
    }, [dispatch]);

    const handleSelectClick = () => {
        setShowFullNames(!showFullNames);
    };

    useEffect(() => {
        if (installations?.length > 0) {
            const installation = installations[0]?.name;
            const address = installations[0]?.installation_adress;
            setinstallationAddress(address);
            setSelectedInstallationId(installation);
        }
    }, [installations]);

    const renderValue = () => {
        const installation = installations[0]?.name;
        return (
            <Typography variant="subtitle1" fontWeight="bold" component="span">
                {installation}
            </Typography>
        );
    };

    return (
        <Select
            onOpen={handleSelectClick}
            onClose={handleSelectClick}
            value={selectedInstallationId}
            renderValue={renderValue}
            displayEmpty
            sx={{
                width: '300px',
                height: '50px',
                ml: 3,
                zIndex: '0'
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }}
        >
            <MenuItem key={1} value={1}>
                {installationAddress}
            </MenuItem>
        </Select>
    );
};

export default MyDropdown;

// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    currentuser: '',
    roles: [],
    users: [],
    user: [],
    adminUsers: [],
    installations: [],
    installation: [],
    singleRoom: [],
    installationrooms: [],
    installationRooms: [],
    tables: [],
    roomtables: [],
    singleroomtables: [],
    profile: [],
    installationusers: [],
    installationId: '',
    changetoall: '',
    organism: [],
    organisms: [],
    licences: [],
    licence: [],
    installationstrains: [],
    strains: [],
    strain: [],
    roomTypes: [],
    vendortypes: []
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setCurrentUserSuccess(state, action) {
            state.currentuser = action.payload;
        },
        getRolesSuccess(state, action) {
            state.roles = action.payload;
        },
        getUsersSuccess(state, action) {
            state.users = action.payload;
        },
        getAdminUsersSuccess(state, action) {
            state.adminUsers = action.payload;
        },
        getSingleUserSuccess(state, action) {
            state.user = action.payload;
        },
        getInstallationsSuccess(state, action) {
            state.installations = action.payload;
        },
        getInstallationAllRoomsSuccess(state, action) {
            state.installationrooms = action.payload;
        },
        getInstallationRoomsSuccess(state, action) {
            state.installationRooms = action.payload;
        },
        getInstallationSingleRoomSuccess(state, action) {
            state.singleRoom = action.payload;
        },
        getAllTablesSuccess(state, action) {
            state.tables = action.payload;
        },
        getRoomTablesSuccess(state, action) {
            state.roomtables = action.payload;
        },
        getSingleRoomSuccess(state, action) {
            state.singleRoom = action.payload;
        },
        getSingleRoomTablesSuccess(state, action) {
            state.singleroomtables = action.payload;
        },
        getSingleInstallationSuccess(state, action) {
            state.installation = action.payload;
        },
        getUserProfileSuccess(state, action) {
            state.profile = action.payload;
        },
        getInstallationUsersSuccess(state, action) {
            state.installationusers = action.payload;
        },
        changeInstallationId(state, action) {
            state.installationId = action.payload;
        },
        changetoAll(state, action) {
            state.changetoall = action.payload;
        },
        getOrganismSuccess(state, action) {
            state.organism = action.payload;
        },
        getAllOrganismsSuccess(state, action) {
            state.organisms = action.payload;
        },
        getAllLicencesSuccess(state, action) {
            state.licences = action.payload;
        },
        getSingleLicenceSuccess(state, action) {
            state.licence = action.payload;
        },
        getAllStrainSuccess(state, action) {
            state.strains = action.payload;
        },
        getSingleStrainSuccess(state, action) {
            state.strain = action.payload;
        },
        getRoomTypesSuccess(state, action) {
            state.roomTypes = action.payload;
        },
        getVendorTypesSuccess(state, action) {
            state.vendortypes = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export const userActions = slice.actions;

// ----------------------------------------------------------------------
export function getUsers() {
    return async () => {
        try {
            const response = await axios.get('/api/users');
            dispatch(slice.actions.getUsersSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getAdminUsers() {
    return async () => {
        try {
            const response = await axios.get('/api/adminUsers');
            dispatch(slice.actions.getAdminUsersSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getSingleUser(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/user/${id}`);
            dispatch(slice.actions.getSingleUserSuccess(response.data.user));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getRoles() {
    return async () => {
        try {
            const response = await axios.get('/api/roles');
            dispatch(slice.actions.getRolesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getInstallations() {
    return async () => {
        try {
            const response = await axios.get('api/installations');
            dispatch(slice.actions.getInstallationsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getSingleInstallation(id) {
    return async () => {
        try {
            const response = await axios.get(`api/installations/${id}`);
            dispatch(slice.actions.getSingleInstallationSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getInstallationAllRooms() {
    return async (dispatch) => {
        try {
            const response = await axios.get(`api/rooms`);
            dispatch(slice.actions.getInstallationAllRoomsSuccess(response.data.data));
        } catch (error) {
            console.log(error.error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getInstallationRooms(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/api/installation-rooms/${id}`);
            dispatch(slice.actions.getInstallationRoomsSuccess(response.data.data));
        } catch (error) {
            console.log(error.error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getInstallationSingleRoom(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`api/rooms/${id}`);
            dispatch(slice.actions.getSingleRoomSuccess(response.data.data));
        } catch (error) {
            console.log(error.error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getAllRoomTables() {
    return async (dispatch) => {
        try {
            const response = await axios.get(`api/tables`);
            dispatch(slice.actions.getAllTablesSuccess(response.data.data));
        } catch (error) {
            console.log(error.error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getRoomTables(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`api/room-tables/${id}`);
            dispatch(slice.actions.getRoomTablesSuccess(response.data.data));
        } catch (error) {
            console.log(error.error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getAllInstallationRooms(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`api/installation-rooms/${id}`);
            dispatch(slice.actions.getAllInstallationRoomsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getSingleRoomTables(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`api/tables/${id}`);
            dispatch(slice.actions.getSingleRoomTablesSuccess(response.data.data));
        } catch (error) {
            console.log(error.error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserProfile() {
    return async () => {
        try {
            const response = await axios.get(`api/user-profile`);
            dispatch(slice.actions.getUserProfileSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getInstallationUsers() {
    return async () => {
        try {
            const response = await axios.get(`api/installation-users`);
            dispatch(slice.actions.getInstallationUsersSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getOrganism() {
    return async () => {
        try {
            const response = await axios.get('/api/organisms');
            dispatch(slice.actions.getOrganismSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getSingleOrganism(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/api/organisms/${id}`);
            dispatch(slice.actions.getOrganismSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getAllOrganisms() {
    return async () => {
        try {
            const response = await axios.get('/api/organisms');
            dispatch(slice.actions.getAllOrganismsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getLicences() {
    return async (dispatch) => {
        try {
            const response = await axios.get('/api/licences');
            dispatch(slice.actions.getAllLicencesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getSingleLicence(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/api/licence/${id}`);
            dispatch(slice.actions.getSingleLicenceSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getAllStrain() {
    return async (dispatch) => {
        try {
            const response = await axios.get('api/strains');
            dispatch(slice.actions.getAllStrainSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getSingleStrain(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`api/strains/${id}`);
            dispatch(slice.actions.getSingleStrainSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getRoomTypes() {
    return async (dispatch) => {
        try {
            const response = await axios.get('api/room-types');
            dispatch(slice.actions.getRoomTypesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getVendorTypes() {
    return async (dispatch) => {
        try {
            const response = await axios.get('api/vendor-types');
            dispatch(slice.actions.getVendorTypesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setCurrentUser(user) {
    return dispatch(slice.actions.setCurrentUserSuccess(user));
}

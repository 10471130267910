/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import Pusher from 'pusher-js';
// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import accountReducer from 'store/accountReducer';
import { openSnackbar } from 'store/slices/snackbar';

// constant
export const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const setSession = (user, expiresIn) => {
    const expiryTime = Date.now() + expiresIn * 1000;

    localStorage.setItem('tokenExpiry', expiryTime.toString());
    localStorage.setItem('selectedCompanyId', user?.company_id);
    localStorage.setItem('roleId', user?.role_id);
    localStorage.setItem('user_id', user?.id);
    localStorage.setItem('selectedInstallation', user?.installation_id);
};

export const refreshTokenIfNeeded = async () => {
    try {
        const response = await axios.post('/api/auth/refresh');

        if (response && response.data) {
            const { user, expires_in } = response.data;
            setSession(user, expires_in);
            return true;
        }
        if (response?.status === 401) {
            return null;
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        return null;
    }
    return null;
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axios.get('/api/auth/user');
                if (response && response.data) {
                    const { user, expires_in } = response.data;
                    setSession(user, expires_in);
                    dispatch({ type: 'LOGIN', payload: { user } });
                } else {
                    dispatch({ type: 'LOGOUT' });
                }
            } catch (err) {
                console.error('Initialization error:', err);
                dispatch({ type: 'LOGOUT' });
            }
        };

        init();
    }, []);

    // ======================== || USER RELATED || ======================== //
    const login = async (email, password) => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (!cookieConsent) {
            alert('You must accept the cookies to login');
            return;
        }
        const response = await axios.post('/api/auth/login', { email, password });
        const { user } = response.data;
        const expiresIn = response.data.expires_in;
        setSession(user, expiresIn);
        if (!window?.Pusher) {
            window.Pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
                cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
                encrypted: true,
                authEndpoint: `${process.env.REACT_APP_API_URL}api/broadcasting/auth`,
                forceTLS: true,
                auth: {
                    params: {
                        user_id: user.id
                    }
                }
            });
        }
        dispatch({ type: 'LOGIN', payload: { user } });
    };

    const register = async (email, password, confirmPassword, fullName, roleId, phone, address) => {
        const response = await axios.post('/api/users', {
            email,
            password,
            confirm_password: confirmPassword,
            full_name: fullName,
            role_id: roleId,
            phone,
            adress_user: address
        });
        const responseData = response.data;
        dispatch({ type: 'REGISTER', payload: { responseData } });
        return responseData;
    };

    const updateUser = async (id, email, password, fullName, roleId, installationId, phone, address) => {
        const response = await axios.put(`/api/users/${id}`, {
            email,
            password,
            full_name: fullName,
            role_id: roleId,
            installation_id: installationId,
            phone,
            adress_user: address
        });
        const users = response.data;
        return users;
    };

    const updateProfile = async (fullName, email) => {
        const response = await axios.post('/api/update-user-profile', {
            fullName,
            email
        });
        const users = response.data;
        return users;
    };

    const logout = () => {
        axios
            .post('/api/auth/logout')
            .then(() => {
                // remove local storage stored
                localStorage.removeItem('tokenExpiry');
                localStorage.removeItem('selectedCompanyId');
                localStorage.removeItem('roleId');
                localStorage.removeItem('user_id');
                localStorage.removeItem('selectedInstallation');
                localStorage.removeItem('isDev');
                if (window.Pusher !== undefined && window.Pusher !== null) {
                    delete window.Pusher;
                }
                setSession(null);
                dispatch({ type: 'LOGOUT' });
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const ChangePassword = async (oldPassword, newPassword, confirmPassword) => {
        const response = await axios.post('/api/reset-password', {
            oldPassword,
            newPassword,
            confirmPassword
        });
        const result = response.data;
        return result;
    };

    const resetPassword = async (email) => {
        const response = await axios.post('/api/forget/password/request', {
            email
        });
        const result = response.data;
        return result;
    };

    const UpdatePassword = async (newPassword, passwordConfirmation, token) => {
        const response = await axios.post('/api/update-password', {
            newPassword,
            passwordConfirmation,
            token
        });
        const result = response.data;
        return result;
    };

    const deleteUser = async (id) => {
        const response = await axios.delete(`/api/users/${id}`);
        const companies = response.data;
        return companies;
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    // ======================== || INSTALLATIONS RELATED || ======================== //
    const addInstallation = async (payload) => {
        const response = await axios.post('/api/installations', payload);
        const result = response.data;
        return result;
    };
    const updateInstallation = async (id, payload) => {
        const response = await axios.put(`/api/installations/${id}`, payload);
        const companies = response.data;
        return companies;
    };
    const deleteInstallation = async (id) => {
        const response = await axios.delete(`/api/installations/${id}`);
        const companies = response.data;
        return companies;
    };
    const updateLastInstallation = async (lastInstallationId, lastCompanyId) => {
        const response = await axios.post('/api/updateInstallations', {
            lastInstallationId,
            lastCompanyId
        });
        const users = response.data;
        return users;
    };

    // ======================== || ROOMS RELATED || ======================== //
    const addInstallationRoom = async (payload) => {
        try {
            const response = await axios.post('/api/rooms', payload);
            const room = response.data;
            return room;
        } catch (error) {
            throw error;
        }
    };
    const updateInstallationRoom = async (roomId, payload) => {
        try {
            const response = await axios.put(`/api/rooms/${roomId}`, payload);
            const room = response.data;
            return room;
        } catch (error) {
            throw error;
        }
    };
    const addRoomTable = async (payload) => {
        try {
            const response = await axios.post(`/api/tables`, payload);
            const table = response.data;
            return table;
        } catch (error) {
            throw error;
        }
    };
    const updateRoomTable = async (tableId, payload) => {
        try {
            const response = await axios.put(`/api/tables/${tableId}`, payload);
            const table = response.data;
            return table;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || ORGANISMS RELATED || ======================== //
    const addOrganism = async (formData) => {
        const response = await axios.post('/api/organisms/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        const organism = response.data;
        return organism;
    };
    const updateOrganism = async (id, formData) => {
        const response = await axios.post(`/api/organisms/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        const organism = response.data;
        return organism;
    };
    const deleteOrganism = async (id) => {
        const response = await axios.delete(`/api/organisms/${id}`);
        const licences = response.data;
        return licences;
    };

    // ======================== || LICENCES RELATED || ======================== //
    const addLicence = async (formData) => {
        const response = await axios.post('/api/licence', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        const licences = response.data;
        return licences;
    };
    const updateLicence = async (id, formData) => {
        const response = await axios.post(`/api/licence/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        const licences = response.data;
        return licences;
    };
    const deleteLicence = async (id) => {
        const response = await axios.delete(`/api/licence/${id}`);
        const licences = response.data;
        return licences;
    };

    // ======================== || STRAINS RELATED || ======================== //
    const addStrain = async (payload) => {
        try {
            const response = await axios.post('api/strains', payload);
            const strain = response.data;
            return strain;
        } catch (error) {
            throw error;
        }
    };
    const updateStrain = async (id, payload) => {
        try {
            const response = await axios.post(`api/strains/${id}`, payload);
            const strain = response.data;
            return strain;
        } catch (error) {
            throw error;
        }
    };
    const deleteStrain = async (id) => {
        try {
            const response = await axios.delete(`/api/strains/${id}`);
            const strain = response.data;
            return strain;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || IMPORTATIONS RELATED || ======================== //
    const addImportation = async (payload) => {
        try {
            const response = await axios.post(`/api/transactions/importation`, payload);
            const importation = response.data;
            return importation;
        } catch (error) {
            throw error;
        }
    };
    const updateImportation = async (id, payload) => {
        try {
            const response = await axios.put(`/api/importations/update/${id}`, payload);
            const importation = response.data;
            return importation;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || CONTAINERS RELATED || ======================== //
    const addContainerModel = async (payload) => {
        try {
            const response = await axios.post(`/api/container-models/`, payload);
            const containerModel = response.data;
            return containerModel;
        } catch (error) {
            throw error;
        }
    };
    const updateContainerModel = async (id, payload) => {
        try {
            const response = await axios.put(`/api/container-models/${id}`, payload);
            const containerModel = response.data;
            return containerModel;
        } catch (error) {
            throw error;
        }
    };
    const deleteContainerModel = async (id) => {
        try {
            const response = await axios.delete(`/api/container-models/${id}`);
            const containerModel = response.data;
            return containerModel;
        } catch (error) {
            throw error;
        }
    };
    const createChildContainer = async (payload) => {
        try {
            const response = await axios.post(`/api/create-child-container`, payload);
            const containers = response.data;
            return containers;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || BATCH/PRODUCT RELATED || ======================== //
    const updateProduct = async (id, payload) => {
        try {
            const response = await axios.put(`/api/products/update/${id}`, payload);
            const products = response.data;
            return products;
        } catch (error) {
            throw error;
        }
    };
    const addBatch = async (payload) => {
        try {
            const response = await axios.post(`/api/batches/`, payload);
            const batch = response.data;
            return batch;
        } catch (error) {
            throw error;
        }
    };
    const createDryBatch = async (payload) => {
        try {
            const response = await axios.post(`/api/batches/dry`, payload);
            const batch = response.data;
            return batch;
        } catch (error) {
            throw error;
        }
    };
    const updateBatch = async (id, payload) => {
        try {
            const response = await axios.put(`/api/batches/${id}`, payload);
            const batch = response.data;
            return batch;
        } catch (error) {
            throw error;
        }
    };
    const addProductToBatch = async (id, payload) => {
        try {
            const response = await axios.post(`/api/product-batches/${id}`, payload);
            const batch = response.data;
            return batch;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || CONVERSION RELATED || ======================== //
    const conversionFreshToDry = async (payload) => {
        try {
            const response = await axios.post(`/api/conversion-fresh-dry`, payload);
            const conversion = response.data;
            return conversion;
        } catch (error) {
            throw error;
        }
    };
    const conversion = async (payload) => {
        try {
            const response = await axios.post(`/api/conversion`, payload);
            const conversion = response.data;
            return conversion;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || EXPEDITION RELATED || ======================== //
    const sendTraysToExpedition = async (payload) => {
        try {
            const response = await axios.post(`/api/send-trays-to-expedition`, payload);
            const expedition = response.data;
            return expedition;
        } catch (error) {
            throw error;
        }
    };
    const sendToExpedition = async (payload) => {
        try {
            const response = await axios.post(`/api/send-to-expedition`, payload);
            const expedition = response.data;
            return expedition;
        } catch (error) {
            throw error;
        }
    };
    const sendMultipleProductToExpedition = async (payload) => {
        try {
            const response = await axios.post(`/api/send-multiple-products-to-expedition`, payload);
            const expedition = response.data;
            return expedition;
        } catch (error) {
            throw error;
        }
    };
    const executeExpedition = async (payload) => {
        try {
            const response = await axios.post(`/api/execute-expedition`, payload);
            const expedition = response.data;
            return expedition;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || DESTRUCTION RELATED || ======================== //
    const sendToDestructionMultiple = async (payload) => {
        try {
            const response = await axios.post(`/api/send-to-destruction-multiple`, payload);
            const destruction = response.data;
            return destruction;
        } catch (error) {
            throw error;
        }
    };
    const sendToDestruction = async (payload) => {
        try {
            const response = await axios.post(`/api/send-to-destruction`, payload);
            const destruction = response.data;
            return destruction;
        } catch (error) {
            throw error;
        }
    };
    const refuseProduct = async (payload) => {
        try {
            const response = await axios.post(`/api/transactions/product/send-to-destruction`, payload);
            const destruction = response.data;
            return destruction;
        } catch (error) {
            throw error;
        }
    };
    const executeDestruction = async (payload) => {
        try {
            const response = await axios.post(`/api/execute-destruction`, payload);
            const destruction = response.data;
            return destruction;
        } catch (error) {
            throw error;
        }
    };
    const addDestructionReason = async (payload) => {
        try {
            const response = await axios.post(`/api/destruction-reasons/store`, payload);
            const destructionReason = response.data;
            return destructionReason;
        } catch (error) {
            throw error;
        }
    };
    const deleteDestructionReason = async (id) => {
        try {
            const response = await axios.post(`/api/destruction-reasons/destroy/${id}`);
            const destructionReason = response.data;
            return destructionReason;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || SUBLOTS RELATED || ======================== //
    const createSublot = async (payload) => {
        try {
            const response = await axios.post(`/api/sublots/store`, payload);
            const sublot = response.data;
            return sublot;
        } catch (error) {
            throw error;
        }
    };

    const updateSublot = async (id, payload) => {
        try {
            const response = await axios.put(`/api/sublots/${id}`, payload);
            const sublot = response.data;
            return sublot;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || TRAYS RELATED || ======================== //
    const createTrays = async (payload) => {
        try {
            const response = await axios.post(`/api/create-trays`, payload);
            const trays = response.data;
            return trays;
        } catch (error) {
            throw error;
        }
    };

    const updateTrays = async (payload) => {
        try {
            const response = await axios.post(`/api/update-trays`, payload);
            const trays = response.data;
            return trays;
        } catch (error) {
            throw error;
        }
    };

    const reweightTrays = async (payload) => {
        try {
            const response = await axios.post(`/api/reweight-trays`, payload);
            const trays = response.data;
            return trays;
        } catch (error) {
            throw error;
        }
    };

    const destructionTrays = async (payload) => {
        try {
            const response = await axios.post(`/api/destruction-trays`, payload);
            const trays = response.data;
            return trays;
        } catch (error) {
            throw error;
        }
    };

    const transferTrays = async (payload) => {
        try {
            const response = await axios.post(`/api/transfer-trays`, payload);
            const trays = response.data;
            return trays;
        } catch (error) {
            throw error;
        }
    };

    const partialTrayConversion = async (payload) => {
        try {
            const response = await axios.post(`/api/partial-tray-conversion`, payload);
            const trays = response.data;
            return trays;
        } catch (error) {
            throw error;
        }
    };

    const totalTrayConversion = async (payload) => {
        try {
            const response = await axios.post(`/api/total-tray-conversion`, payload);
            const trays = response.data;
            return trays;
        } catch (error) {
            throw error;
        }
    };

    const retentionTrays = async (payload) => {
        try {
            const response = await axios.post(`/api/retention/trays`, payload);
            const trays = response.data;
            return trays;
        } catch (e) {
            throw e;
        }
    };

    const deleteTrays = async (id) => {
        try {
            const response = await axios.delete(`/api/delete-tray/${id}`);
            const trays = response.data;
            return trays;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || REPORTS RELATED || ======================== //
    const deleteReport = async (id) => {
        try {
            const response = await axios.delete(`/api/reports/${id}`);
            const reports = response.data;
            return reports;
        } catch (error) {
            throw error;
        }
    };
    const createCTLSReport = async (payload) => {
        try {
            const response = await axios.post(`/api/report/ctls`, payload);
            const reports = response.data;
            return reports;
        } catch (error) {
            throw error;
        }
    };
    const createB300Report = async (payload) => {
        try {
            const response = await axios.post(`/api/report/b300`, payload);
            const reports = response.data;
            return reports;
        } catch (error) {
            throw error;
        }
    };
    const createDetailedCTLSReport = async (payload) => {
        try {
            const response = await axios.post(`/api/detailed-report/ctls`, payload);
            const reports = response.data;
            return reports;
        } catch (error) {
            throw error;
        }
    };
    const createDetailedB300Report = async (payload) => {
        try {
            const response = await axios.post(`/api/detailed-report/b300`, payload);
            const reports = response.data;
            return reports;
        } catch (error) {
            throw error;
        }
    };
    const lockReport = async (id) => {
        try {
            const response = await axios.post(`/api/lock-report/${id}`);
            const reports = response.data;
            return reports;
        } catch (error) {
            throw error;
        }
    };
    const getReport = async (pdfUrl) => {
        try {
            const response = await axios.get(`${pdfUrl}`, {
                responseType: 'blob'
            });
            const reports = response;
            return reports;
        } catch (error) {
            throw error;
        }
    };
    const getLicencePdf = async (pdfUrl) => {
        try {
            const response = await axios.get(`${pdfUrl}`, { responseType: 'blob' });
            const reports = response;
            return reports;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || NON FLOWERS RELATED || ======================== //
    const updateNonFlower = async (id, payload) => {
        try {
            const response = await axios.put(`/api/non-flower/${id}`, payload);
            const nonflower = response.data;
            return nonflower;
        } catch (error) {
            throw error;
        }
    };
    const verifyWeeklyPassword = async (password) => {
        try {
            const response = await axios.post(`/api/weekly-password/verify`, { password });
            const result = response.data;
            return result;
        } catch (error) {
            throw error;
        }
    };

    // ======================== || UNDO RELATED || ======================== //
    const executeUndo = async (data) => {
        try {
            const response = await axios.post(`/api/undo/`, data);
            const result = response.data;
            return result;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };
    // ======================== || INVENTORY RELATED || ======================== //
    const generateInventoryReport = async () => {
        try {
            const response = await axios.get(`/api/inventory-snapshots/pdf`);
            const reports = response.data;
            return reports;
        } catch (error) {
            throw error;
        }
    };

    const deleteInventoryReport = async (id) => {
        try {
            const response = await axios.delete(`/api/inventory-snapshots/${id}`);
            const reports = response.data;
            return reports;
        } catch (error) {
            throw error;
        }
    };

    const requestReportDeleteOTP = async (data) => {
        try {
            const response = await axios.post('/api/reports/delete-otp/request', data);
            return response.data;
        } catch (err) {
            throw new Error(err.response?.data?.error || 'Error requesting OTP');
        }
    };

    const verifyReportDeleteOTP = async (data) => {
        try {
            const response = await axios.post('/api/reports/delete-otp/verify', data);
            return response.data;
        } catch (err) {
            throw new Error(err.response?.data?.error || 'Error verifying OTP');
        }
    };
    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout,
                register,
                resetPassword,
                updateProfile,
                updateUser,
                deleteUser,
                UpdatePassword,
                addInstallation,
                deleteInstallation,
                updateInstallation,
                updateInstallationRoom,
                addInstallationRoom,
                addRoomTable,
                updateRoomTable,
                ChangePassword,
                updateLastInstallation,
                addOrganism,
                updateOrganism,
                deleteOrganism,
                addLicence,
                updateLicence,
                deleteLicence,
                addStrain,
                updateStrain,
                deleteStrain,
                addContainerModel,
                updateContainerModel,
                deleteContainerModel,
                addImportation,
                updateImportation,
                updateProduct,
                addBatch,
                createDryBatch,
                addProductToBatch,
                conversion,
                conversionFreshToDry,
                sendToExpedition,
                sendMultipleProductToExpedition,
                executeExpedition,
                addDestructionReason,
                executeDestruction,
                deleteDestructionReason,
                sendToDestruction,
                createTrays,
                updateTrays,
                retentionTrays,
                transferTrays,
                destructionTrays,
                reweightTrays,
                partialTrayConversion,
                totalTrayConversion,
                deleteReport,
                getReport,
                getLicencePdf,
                createCTLSReport,
                createB300Report,
                createDetailedCTLSReport,
                createDetailedB300Report,
                updateNonFlower,
                createSublot,
                updateSublot,
                sendTraysToExpedition,
                deleteTrays,
                verifyWeeklyPassword,
                sendToDestructionMultiple,
                refuseProduct,
                executeUndo,
                createChildContainer,
                updateBatch,
                generateInventoryReport,
                deleteInventoryReport,
                lockReport,
                requestReportDeleteOTP,
                verifyReportDeleteOTP
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;

// remove eslint camelcase rule
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/**
 * axios setup to use mock service
 */
import axios from 'axios';

// Primary Axios instance pointing to the environment API URL
const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_API_URL || process.env.REACT_APP_SERVER_IP, // Direct fallback to IP
    withCredentials: true
});

// Automatically refresh the token if necessary before making requests
axiosServices.interceptors.request.use(
    async (config) => {
        // Fetch the CSRF token if it's not already fetched
        if (!document.cookie.includes('XSRF-TOKEN') && document.cookie.includes('jwt_token')) {
            /* await axiosServices.get('/sanctum/csrf-cookie'); */
        }

        return config;
    },
    (error) => Promise.reject(error)
);

let globalRetryCount = 0;

const disconnectUser = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.setItem('isDisconnected', true);
    if (window.Pusher !== undefined && window.Pusher !== null) {
        window.Pusher.disconnect();
        window.Pusher.unsubscribe();
        delete window.Pusher;
    }
    window.location.href = '/login';
};

// Add an interceptor to handle token refresh and fallback to IP address on failure
axiosServices.interceptors.response.use(
    (response) => {
        // Check if the response contains is_dev and store it in localStorage
        if (response.data && Object.prototype.hasOwnProperty.call(response.data, 'is_dev')) {
            localStorage.setItem('isDev', response.data.is_dev);
        }
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Check if the error is due to an expired token and if it's not already retried 5 times
        if (localStorage.getItem('isLoggedIn') && error?.response?.status === 401 && globalRetryCount < 5) {
            globalRetryCount += 1;

            try {
                // Attempt to refresh the token by calling the refresh endpoint
                const refreshResponse = await axiosServices.post('/api/auth/refresh');
                if (refreshResponse.status === 200) {
                    // Set the new access token in the Authorization header
                    globalRetryCount = 0;
                    axiosServices.defaults.headers.common.Authorization = `Bearer ${refreshResponse.data.access_token}`;
                    originalRequest.headers.Authorization = `Bearer ${refreshResponse.data.access_token}`;

                    // Retry the original request with the new access token
                    return axiosServices(originalRequest);
                }
                throw new Error('Token refresh failed');
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                return Promise.reject(refreshError);
            }
        }

        // Move this check outside of the try-catch block
        if (globalRetryCount >= 5 && error?.response?.status === 401) {
            disconnectUser();
            globalRetryCount = 0;
            return Promise.reject(error.response.data || 'Max retry attempts reached');
        }

        return Promise.reject((error.response && error.response.data) || 'Error with the service');
    }
);

export default axiosServices;

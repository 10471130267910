import React, { useState, useEffect } from 'react';
import { Box, Button, Snackbar, Typography, Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useAuth from 'hooks/useAuth';

const CookieConsent = () => {
    const [open, setOpen] = useState(false);
    const { logout } = useAuth();

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setOpen(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        document.cookie = `cookieConsent=true;path=/;max-age=31536000;SameSite=Strict;${
            window.location.protocol === 'https:' ? 'Secure;' : ''
        }`;
        setOpen(false);
    };

    const handleDecline = () => {
        localStorage.setItem('cookieConsent', 'false');
        document.cookie = `cookieConsent=false;path=/;max-age=31536000;SameSite=Strict;${
            window.location.protocol === 'https:' ? 'Secure;' : ''
        }`;
        setOpen(false);
        logout();
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{
                '& .MuiSnackbarContent-root': {
                    maxWidth: '600px',
                    backgroundColor: 'background.paper',
                    boxShadow: 24
                }
            }}
        >
            <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 3 }}>
                <Typography variant="body1" color="textPrimary" sx={{ mb: 2 }}>
                    <FormattedMessage id="cookie-consent-text" />
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Button variant="outlined" color="primary" onClick={handleDecline}>
                        <FormattedMessage id="decline" />
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleAccept}>
                        <FormattedMessage id="accept" />
                    </Button>
                </Box>
            </Box>
        </Snackbar>
    );
};

export default CookieConsent;

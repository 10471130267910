// eslint-disable-no-nested-ternary
import { useCallback, useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { Icon24Hours, IconBell } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getUndos } from 'store/slices/undos';
import UndoList from './UndosList';
import Loader from 'ui-component/Loader';

// notification status options
const status = [
    {
        value: 'all',
        label: 'Tout'
    },
    {
        value: 'importations',
        label: 'Importations'
    },
    {
        value: 'batch',
        label: 'Lots'
    },
    {
        value: 'conversion-production',
        label: 'Conversion de production'
    },
    {
        value: 'conversion-storage',
        label: 'Conversion de storage'
    },
    {
        value: 'trays',
        label: 'Contenants'
    },
    {
        value: 'destructions',
        label: 'Destructions'
    },
    {
        value: 'expeditions',
        label: 'Expeditions'
    },
    {
        value: 'sublots',
        label: 'Sous-lots'
    }
];

// ==============================|| NOTIFICATION ||============================== //

const UndoSection = () => {
    const userId = parseFloat(localStorage.getItem('user_id'));

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const [allUndos, setAllUndos] = useState([]);
    const [allUndosState, setAllUndosState] = useState([]);
    const [optionChosen, setOptionChosen] = useState('all');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [listTitle, setListTitle] = useState('');
    const [listSize, setListSize] = useState(0);
    const [newUndoEvent, setNewUndoEvent] = useState(false);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const anchorRef = useRef(null);

    const { undos } = useSelector((state) => state.undos);

    if (window?.Pusher && window?.Pusher?.connection?.state === 'connected') {
        const undoChannel = window?.Pusher?.subscribe(`private-new-undo-event.${userId}`);
        undoChannel.bind('undo.action', (data) => {
            if (data?.undoAction?.user_id === userId) {
                setNewUndoEvent(true);
            }
        });
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        dispatch(getUndos());
    }, [newUndoEvent]);

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    function mergeBatchesArray() {
        const allbatches = [];

        allUndos?.batches?.forEach((batch) => {
            allbatches.push({
                id: batch.id,
                action: batch.action,
                action_date: batch.action_date,
                weight: batch.total_weight > 0 ? batch.total_weight : batch.total_quantity,
                is_weight: batch.total_weight > 0,
                product_strains: batch.product_strains,
                batch_id: batch?.batch_identifier || batch?.batch_id || '-'
            });
        });
        allUndos?.batches2?.forEach((batch) => {
            allbatches.push({
                id: batch.id,
                action: batch.action,
                action_date: batch.action_date,
                weight: batch.total_weight > 0 ? batch.total_weight : batch.total_quantity || 0,
                is_weight: batch.total_weight > 0,
                product_strains: batch.product_strains,
                batch_id: batch?.batch_identifier || batch?.batch_id || '-'
            });
        });
        return allbatches;
    }

    function createUndoItem(item, type) {
        let returnItem = {};
        returnItem = {
            id: item.id,
            action: item.action,
            action_date: item.action_date,
            weight: item.total_weight > 0 ? item.total_weight : item.total_quantity || 0,
            product_strains: item.product_strains,
            is_weight: item.total_weight > 0,
            batch_id: item?.batch_identifier || item?.batch_id || '-',
            type
        };
        if (type === 'trays' && item?.traysIds) {
            returnItem.traysIds = item.traysIds;
        }
        return returnItem;
    }

    function createOneBigUndoArray(allUndos) {
        const allUndosArray = [];

        const undoTypes = [
            { key: 'destructions', type: 'destructions' },
            { key: 'expeditions', type: 'expeditions' },
            { key: 'importations', type: 'importations' },
            { key: 'productConversions', type: 'product-conversions' },
            { key: 'storageConversions', type: 'storage-conversions' },
            { key: 'trays', type: 'trays' },
            { key: 'sublots', type: 'sublots' },
            { key: 'batches', type: 'batches' }
        ];

        undoTypes.forEach(({ key, type, customFetch }) => {
            if (customFetch && key === 'batches') {
                mergeBatchesArray().forEach((batch) => {
                    allUndosArray.push(createUndoItem(batch, type));
                });
            } else {
                allUndos?.[key]?.forEach((item) => {
                    allUndosArray.push(createUndoItem(item, type));
                });
            }
        });
        // sort by date asc
        allUndosArray.sort((a, b) => new Date(b.action_date) - new Date(a.action_date));
        setAllUndosState(allUndosArray);
        return allUndosArray;
    }

    useEffect(() => {
        const undosFormatted = createOneBigUndoArray(undos?.data);
        setAllUndos(undosFormatted);
    }, [undos, newUndoEvent]);

    const handleChange = (event) => setValue(event?.target.value);

    function returnUndoListByType(type) {
        const returnList = [];
        allUndos.forEach((undo) => {
            if (undo.type === type) {
                returnList.push(undo);
            }
        });
        return returnList;
    }

    const returnListTitle = () => {
        switch (optionChosen) {
            case 'destructions':
                setListTitle('Destructions');
                break;
            case 'expeditions':
                setListTitle('Expéditions');
                break;
            case 'importations':
                setListTitle('Importations');
                break;
            case 'batches':
                setListTitle('Lots');
                break;
            case 'production-conversion':
                setListTitle('Conversion de production');
                break;
            case 'storage-conversions':
                setListTitle('Conversion de storage');
                break;
            case 'trays':
                setListTitle('Contenants');
                break;
            case 'sublots':
                setListTitle('Sous-lots');
                break;
            default:
                setListTitle('Tout');
        }
    };

    const returnListSize = () => {
        let size = 0;
        switch (optionChosen) {
            case 'destructions':
                size = returnUndoListByType('destructions')?.length;
                break;
            case 'expeditions':
                size = returnUndoListByType('expeditions')?.length;
                break;
            case 'importations':
                size = returnUndoListByType('importations')?.length;
                break;
            case 'batches':
                size = mergeBatchesArray().length;
                break;
            case 'product-conversions':
                size = returnUndoListByType('product-conversions')?.length;
                break;
            case 'storage-conversions':
                size = returnUndoListByType('storage-conversions')?.length;
                break;
            case 'trays':
                size = returnUndoListByType('trays')?.length;
                break;
            case 'sublots':
                size = returnUndoListByType('sublots')?.length;
                break;
            default:
                size = allUndosState.length;
        }
        setListSize(size);
        return size;
    };

    useEffect(() => {
        returnListSize();
        returnListTitle();
    }, [newUndoEvent, optionChosen]);

    const renderUndoList = () => {
        switch (optionChosen) {
            case 'destructions':
                return (
                    <UndoList
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        data={{ type: 'destructions', data: returnUndoListByType('destructions') || [] }}
                    />
                );
            case 'expeditions':
                return (
                    <UndoList
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        data={{ type: 'expeditions', data: returnUndoListByType('expeditions') || [] }}
                    />
                );
            case 'importations':
                return (
                    <UndoList
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        data={{ type: 'importations', data: returnUndoListByType('importations') || [] }}
                    />
                );
            case 'batches':
                return (
                    <UndoList
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        data={{ type: 'batches', data: mergeBatchesArray() }}
                    />
                );
            case 'conversion-production':
                return (
                    <UndoList
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        data={{
                            type: 'product-conversions',
                            data: returnUndoListByType('product-conversions') || []
                        }}
                    />
                );
            case 'conversion-storage':
                return (
                    <UndoList
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        data={{
                            type: 'storage-conversions',
                            data: returnUndoListByType('storage-conversions') || []
                        }}
                    />
                );
            case 'trays':
                return (
                    <UndoList
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        data={{ type: 'trays', data: returnUndoListByType('trays') || [] }}
                    />
                );
            case 'sublots':
                return (
                    <UndoList
                        isSubmitting={isSubmitting}
                        setIsSubmitting={setIsSubmitting}
                        data={{ type: 'sublots', data: returnUndoListByType('sublots') || [] }}
                    />
                );
            default:
                return (
                    <UndoList isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} data={{ type: 'all', data: allUndosState }} />
                );
        }
    };

    return (
        <>
            <Box
                sx={{
                    mr: 2
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.error.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.error.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.error.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.error.light
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="error"
                >
                    <Icon24Hours stroke={1.5} size="20px" />
                </Avatar>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 5 : 0, 20]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography variant="subtitle1">{listTitle}</Typography>
                                                            <Chip
                                                                size="small"
                                                                label={listSize}
                                                                sx={{
                                                                    color: theme.palette.background.default,
                                                                    bgcolor: theme.palette.warning.dark
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar
                                                    style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                                >
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Box sx={{ px: 2, pt: 0.25 }}>
                                                                <TextField
                                                                    id="outlined-select-currency-native"
                                                                    select
                                                                    fullWidth
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        setOptionChosen(e.target.value);
                                                                        handleChange(e);
                                                                    }}
                                                                    SelectProps={{
                                                                        native: true
                                                                    }}
                                                                >
                                                                    {status.map((option) => (
                                                                        <option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider sx={{ my: 0 }} />
                                                        </Grid>
                                                    </Grid>
                                                    {listSize > 0 ? (
                                                        renderUndoList()
                                                    ) : (
                                                        <Box sx={{ p: 2, textAlign: 'center' }}>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                Aucun élément à afficher
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {/*  <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Button size="small" disableElevation>
                                                View All
                                            </Button>
                                        </CardActions> */}
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
            {isSubmitting && <Loader />}
        </>
    );
};

export default UndoSection;
